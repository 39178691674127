import React from "react";
import './../components/Blog/blog.css';
import { useTranslation } from 'react-i18next';
import { Switch, Route,  useRouteMatch } from "react-router-dom";
import MetaDecorator from '../components/MetaDatos/MetaDecorator';
import PostList from '../components/Blog/PostList/PostList';
import ReactGA from 'react-ga';
import { ID_GOOGLE_ANALYTICS } from '../assets/Constants';
import BannerBlog from "../components/Blog/Banner/BannerBlog";
import Post from "./Post";


function Blog () {

    // Configuración de Google Analytics
    ReactGA.initialize ({ID_GOOGLE_ANALYTICS}); 
    ReactGA.pageview ("/blog");

    let { path } = useRouteMatch();
    const { t } = useTranslation();




    return (
          <>

          <Switch>
            <Route exact path={path}>
              <>
                <MetaDecorator  title={t("meta_datos.meta_title_Blog")}
                    description={t("meta_datos.meta_description_Blog")}
                    url="blog" />
                <BannerBlog />
                 <PostList /> 
              </>
            </Route>
            
            <Route exact path={`${path}/:postId`}>
              <Post />
            </Route>
          </Switch>
        </>
    );
}



export default Blog;