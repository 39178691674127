import React from 'react';
import { useTranslation } from 'react-i18next';
import ParallaxTitle from 'components/Titles/ParallaxTitle';
import './benefits.css'

function BenefitsItems ({ title }){

  return (
    <div className='col-lg-auto col-bf-6'>
      <div className="benefits_item b_radious">
          <div className="team_content">
              <h3 className="m-0 f--size-20 f-sm-15 f_600 w_color">{title}</h3>
          </div>
      </div>
    </div>
  )
}



export default function BenefitsList() {

  const { t } = useTranslation();
  const benefistlist = t('benefits.benefits_list', { returnObjects: true })

  return (
    <>
        <section className='benefics--tab sec--area bg_dark'>
          <ParallaxTitle 
              Title ={t('benefits.benefits_tab_title')}
              classDiv ="text-center pb-5 p-sm"
              h2Class ="m_color f_600 f--size-40 f-sm-35"
          />
          <div className="container">
              <div className="row justify-content-center">
                {
                  benefistlist.map((item, index) => (
                    <BenefitsItems 
                      key={index}
                      title={item.benefit}
                    />
                  ))
                }
              </div>
          </div>
          {/* <div className="container">
             <div className='containere'>
                {
                  benefistlist.map((item, index) => (
                    <Items
                      key={index}
                      title={item.benefit}
                    />
                  ))
                }
          </div>
          </div> */}
        </section>
    </>
  )
}
