import React from 'react';
import { useTranslation } from 'react-i18next';
import BannerBenefits from 'components/Careers/BannerBanefists/BannerBenefits';
import BenefitsList from 'components/Careers/BenefitsList/BenefitsList';

function Benefits() {

    const {t} = useTranslation();

  return (
    <>
        <BannerBenefits>
            <div className="col-lg-8">                   
                <div className="w_color text-left pt-3">
                    <h2 className="f--size-50 m_color f_600 l_height09">{t('benefits.banner_title')}</h2>
                    <h3 className="f--size-25 w_color f_600 mb_30">{t('benefits.banner_subTitle')}</h3>  
                    <p className="f--size-18 w_color f_500">{t('benefits.banner_text')}</p>        
                </div>
            </div>
        </BannerBenefits>
        <BenefitsList />

    </>
  )
}

export default Benefits;