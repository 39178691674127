
import './tabCareers.css';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';


function TabCareers({pathUrl}) {

    const { t, i18n } = useTranslation();
    const lang = i18n.language;
    let listJobs;
    lang === 'es'
      ? listJobs = require('assets/jobs/esJobs.json')
      : listJobs = require('assets/jobs/enJobs.json')

    const jobsColors = ['color1', 'color2', 'color3', 'color4'];

    listJobs.forEach((listJobs, index) => {
        listJobs.color = jobsColors[index % jobsColors.length];
    });    

  return (
    <section className="jobs--tab bg_dark sec_pad">
        <h2 className='text-center m_color f--size-40 f_600 mb-4'>{t('careers.title_opportunities')}</h2>
        <div className='container'>
            <div className='row'>
                {
                    listJobs.map((item) => {
                        return (
                            <TabCareersItem 
                                color={item.color}
                                name={item.name}
                                url={item.url}
                                path={pathUrl}
                                modality={item.modality}
                                apply={t('careers.apply')}
                            />
                        )
                    } )
                }
            </div>
        </div>
    </section>
  )
}

export default TabCareers;

function TabCareersItem({ color, name, url, path, modality, apply}) {

    const classIcon = modality === 'Remote' ? "ti-signal" : "ti-user";


  return (
    <div className='col-lg-4 col-md-6 col-sm-12 pb-3' >
        <div className={`media ${"bg_" + color} flex-wrap`} >
            <div className="media-body  w_color">
                <h3 className="f--size-25 f_600" >{name}</h3>
                <h4 className="f--size-20 pt-2 pb-2 f_600 ">
                    {modality}
                    <i className={`pl-2 f--size-15 f_600 ${classIcon}`}></i>
                </h4>
                <div className="btn btn-light f_p f_600">
                <Link className={`${color}`} to={`${path}/${url}`}>{apply}</Link>
                </div>
            </div>
        </div>
    </div>
  )
}
