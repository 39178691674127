import {React } from 'react';
import './video-careers.css';
import { useTranslation } from 'react-i18next';
//import { GiSoundOff } from "react-icons/gi";
import Youtube from 'react-youtube';


function VideoComponent() {

  const { t } = useTranslation();
   /*const [ audioOn, setAudioOn] = useState(false);

    const handleAudio = () => {
      setAudioOn(!audioOn);
    }*/

  return (
    <section className='jobsVideo-section '>
        <div className=" jobsVideo-backgroun">
            {/* <iframe className="jobsVideo-embed" 
                    frameBorder="0" 
                    allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"  
                    title="Sueña para transformar el futuro." 
                    width="640" 
                    height="360" 
                    src={t('careers.url_video')} >
            </iframe> */}
            <Youtube
              videoId={t('careers.url_video')}
              className='jobsVideo-embe'
              opts={{
                width: "100%",
                height: "400",
                playerVars: {
                  autoplay: 1,
                  controls: 0,
                  modestbranding: 0,
                  rel: 0,
                  showinfo: 0,
                  allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
                },
                title : "Sueña para transformar el futuro." 

              }}
            />            
        </div>
        {/* <button className="position-absolute float-left bg-white z-index-9" type="button" ><GiSoundOff />
        </button> */}
    </section>
  ); 
}

export default VideoComponent;
