import React from 'react'
import BannerHome from "../components/Home/BannerH/BannerHome";
import StaffAugmentation from '../components/Home/StaffHome/StaffAugmentation';
import Testimonial from '../components/Home/Testimonial/Testimonial';
import SliderBlogHome from '../components/Home/SliderBlogHome.js/SliderBlogHome';
import OurClients from '../components/Home/OurClients/OurClients';
import ReactGA from 'react-ga';
import { ID_GOOGLE_ANALYTICS } from '../assets/Constants';
import { useTranslation } from 'react-i18next';
import MetaDecorator from '../components/MetaDatos/MetaDecorator';
import Planets from 'components/Home/Planets/Planets';
import BannerGreatPlace from '../components/Home/BannerGreatPlace/BannerGreatPlace';
//import Solar from 'components/Home/Solar/Solar';

    // Configuración de Google Analytics
    ReactGA.initialize ({ID_GOOGLE_ANALYTICS}); 
    ReactGA.pageview ("/");

function Home() {

    const { t } = useTranslation('translation', { keyPrefix: 'meta_datos' });

  return (
    <>
      <MetaDecorator 
        title={t('meta_title_Home')}
        description={t('meta_description_Home')}
        url="" />
      <div className='bg_black starts-home'>
        <BannerHome />
        <Planets />
        {/* <Solar /> */}
      </div>
      <StaffAugmentation />
      <BannerGreatPlace />
      <Testimonial />
      <SliderBlogHome />
      <OurClients />
    </>
  )
}

export default Home;