import React from 'react';
import LogoClients from '../../LogoClients/LogoClients';
import "./ourClients.css"

function OurClients (){


        return(

            <section className="our--clients sec--area " id="clients">
                <div className="container">
                        <div className="row">
                        <div className="col-lg-12">
                            <LogoClients classDiv=" align-items-center logos-container"/>
                        </div>
                    </div>
                </div>
            </section>

        )
    }

export default OurClients;